<template>
  <div>
    <b-col class="flex-column border-box mb-3">
      <h4 class="title">
        <i class="now-ui-icons files_box mr-2" />{{t('Blocked Categories')}}
      </h4>
      <p>{{t('This section helps to manage the blocked categories for the profile')}}</p>
      <div class="px-3 row">
        <div
          class="d-flex"
          style="margin-right: 60px"
          v-for="category in getCategories"
          :key="category.name"
        >
          <p class="mr-3 mb-0 text-capitalize">
            {{ category.name }}
          </p>
          <n-switch
            class="mt-0 mb-0"
            v-model="category.isSelected"
            :visible_for_permissions="visible_for_permissions"
            @input="handleBlockedCategories($event, category.isp_category_id)"
            color="allot"
          />
        </div>
      </div>
    </b-col>
    <div class="d-flex justify-content-between mb-3">
      <b-col cols="6" class="flex-column border-box" style="max-width: 49%">
        <h4 class="title">
          <i class="now-ui-icons files_box mr-2" />{{t('Password override')}}
        </h4>
        <p class="mb-4">
          {{
            t('Blocks the access to the requested URL by showing a blocking page with password. if the user enters the right password, the navigation to forbidden sites will be allowed for a few minutes')
          }}
        </p>
        <div class="px-3 row">
          <div class="d-flex">
            <p class="mr-3 mb-0 text-capitalize">
              {{ t('Password override') }}
            </p>
            <n-switch
              class="mt-0 mb-0"
              v-model="passwordOverride"
              :visible_for_permissions="visible_for_permissions"
              color="allot"
            />
          </div>
        </div>
      </b-col>
      <b-col cols="6" class="flex-column border-box" style="max-width: 49%">
        <h4 class="title">
          <i class="now-ui-icons files_box mr-2" />{{t('Safe Search')}}
        </h4>
        <p class="mb-4">
          {{
            t("When you enable safeSearch searches, images and videos are filterd by the search engine to don't show pornography and potentially offensive content.")
          }}
        </p>
        <div class="px-3 row">
          <div class="d-flex">
            <p class="mr-3 mb-0 text-capitalize">
              {{ t('Safe Search') }}
            </p>
            <n-switch class="mt-0 mb-0" v-model="safSearch" color="allot" :visible_for_permissions="visible_for_permissions"/>
          </div>
        </div>
      </b-col>
    </div>
    <div class="d-flex justify-content-between">
      <b-col cols="6" class="flex-column border-box" style="max-width: 49%">
        <ForbiddenAllowedList
          title="BLOCKED LIST"
          :subtitle="blockedHelpText"
          :list="blockedList"
          action="block"
          @update_list="updateForbiddenAllowedList"
        />
      </b-col>
      <b-col cols="6" class="flex-column border-box" style="max-width: 49%">
        <ForbiddenAllowedList
          title="ALLOWED LIST"
          :subtitle="allowedHelpText"
          :list="allowedList"
          action="allow"
          @update_list="updateForbiddenAllowedList"
        />
      </b-col>
    </div>
  </div>
</template>

<script>
import { ToggleList, Switch } from "@/components";
import GeneralMixin from "../../../AsmConfigurations/general/GeneralMixin";
import ForbiddenAllowedList from "./ForbiddenAllowedList";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import _ from "lodash";

export default {
  components: {
    [ToggleList.name]: ToggleList,
    [Switch.name]: Switch,
    ForbiddenAllowedList,
  },
  mixins: [GeneralMixin],
  props: {
    visible_for_permissions: { 
      type: Array, 
      default: function () { return [] }
    } 
  },
  data() {
    return {
      help_text:
        "Select services that will be globally enabled for the product:",
      allowedHelpText:
        "Add URL or domain that you want always allow ",
      blockedHelpText:
        "Add URL or domain that you want always block ",
    };
  },
  async created() {
    await this.getIspCategories();
  },
  methods: {
    ...mapActions("ispServices/profiles", ["getIspCategories"]),
    ...mapMutations("ispServices/profiles", ["updateProfileServiceProp"]),
    handleBlockedCategories(value, isp_category_id) {
      let categories = _.cloneDeep(this.blockedCategories);
      if (value) categories.push(isp_category_id);
      else {
        categories = categories.filter((cName) => cName !== isp_category_id);
      }
      this.updateProfileServiceProp({
        serviceName: "parental_control",
        propName: "blocked_categories",
        value: categories,
      });
    },
    updateForbiddenAllowedList(listType, list) {
      const propName = listType === "allow" ? "allowed_urls" : "blocked_urls";
      this.updateProfileServiceProp({
        serviceName: "parental_control",
        propName,
        value: list,
      });
    },
  },
  computed: {
    ...mapState("ispServices/profiles", ["profile", "ispCategories"]),
    getCategories(){
      return this.$store.getters['ispServices/profiles/ispCategories'](this.$store.getters['settings/getLanguage']);
    },
    blockedCategories() {
      return this.profile["services_configuration"].parental_control
        .blocked_categories;
    },
    passwordOverride: {
      get() {
        return this.profile.services_configuration.parental_control
          .password_override;
      },
      set(value) {
        this.updateProfileServiceProp({
          serviceName: "parental_control",
          propName: "password_override",
          value,
        });
      },
    },
    safSearch: {
      get() {
        return this.profile.services_configuration.parental_control.safe_search;
      },
      set(value) {
        this.updateProfileServiceProp({
          serviceName: "parental_control",
          propName: "safe_search",
          value,
        });
      },
    },
    allowedList() {
      return this.profile.services_configuration.parental_control.allowed_urls;
    },
    blockedList() {
      return this.profile.services_configuration.parental_control.blocked_urls;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
