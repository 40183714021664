import { render, staticRenderFns } from "./ParentalControlService.vue?vue&type=template&id=c1e25cee&scoped=true&"
import script from "./ParentalControlService.vue?vue&type=script&lang=js&"
export * from "./ParentalControlService.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1e25cee",
  null
  
)

export default component.exports